import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';
import { capitalize } from 'lodash';

const route: RouteObject = {
	path: 'medications',
	loader: () => ({
		meta: {
			title: 'Medications',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/medications/Layout')).default }),
	children: [
		{
			loader: ({ params }) => ({
				meta: {
					pageTitle: params.script,
					title: capitalize(params.script),
				} as RouteMeta,
			}),
			path: ':script',
			index: true,
			id: 'scripts',
			lazy: async () => ({ Component: (await import('@/modules/medications/pages/Scripts')).default }),
		},
		{
			loader: () => ({
				meta: {
					title: 'Assessments',
				} as RouteMeta,
			}),
			path: 'assessments',
			index: true,
			id: 'assessments',
			lazy: async () => ({ Component: (await import('@/modules/medications/pages/Assessment')).default }),
		},
	],
};
export default route;
